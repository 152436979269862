"use client";

import { AdjustmentsHorizontalIcon } from "@heroicons/react/24/outline";
import { usePathname } from "next/navigation";
import SidebarContent from "./sidebarContent";
import { HeroIcon, HOMEPAGE } from "@/lib/utils";
import {
    AlignRight,
    CircleDollarSign,
    CircleHelp,
    FolderClosed,
    GraduationCap,
    House,
    LucideIcon,
    MessageCircle,
    MessageCircleQuestion,
    Sparkles,
} from "lucide-react";
import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet";
import Image from "next/image";
import { useEffect, useState } from "react";
import { PermissionKey } from "@/features/admin/types";
import { showPricingPageClient } from "@/lib/utils";

export type NavigationSingleItem = {
    label: string;
    href: string;
    icon: HeroIcon | LucideIcon;
    requiredPermission?: PermissionKey;
    comingSoon?: boolean;
    keyName: string;
};

export type NavigationMultiItem = {
    label: string;
    icon: HeroIcon | LucideIcon;
    adminOnly?: boolean;
    comingSoon?: boolean;
    keyName: string;
    children: {
        label: string;
        href: string;
        requiredPermission?: PermissionKey;
        comingSoon?: boolean;
        keyName: string;
    }[];
};

export type NavigationItem = NavigationSingleItem | NavigationMultiItem;

const navigation: NavigationItem[] = [
    {
        keyName: "platform.sidebar.home",
        label: "בית",
        href: HOMEPAGE,
        icon: House,
    },
    {
        keyName: "platform.sidebar.storage",
        label: "אחסון",
        icon: FolderClosed,
        href: "/storage",
    },
    {
        keyName: "platform.sidebar.tools",
        label: "כלים",
        icon: Sparkles,
        children: [
            {
                keyName: "platform.sidebar.tools.syllabus",
                label: "יצירת סילאבוס",
                href: "/ai/create-syllabus",
            },
            {
                keyName: "platform.sidebar.tools.presentation",
                label: "יצירת מצגת",
                href: "/ai/create-presentation",
            },
            {
                keyName: "platform.sidebar.tools.upload-videos",
                label: "העלאת סרטונים",
                href: "/ai/upload-videos",
            },
            {
                keyName: "platform.sidebar.tools.landing-page",
                label: "יצירת דף נחיתה",
                href: "/ai/landing-page",
                comingSoon: true,
            },
        ],
    },
    {
        keyName: "platform.sidebar.chat-bots",
        label: "עוזר אישי",
        icon: MessageCircleQuestion,
        href: "/assistants",
    },
    {
        label: "מחירון",
        keyName: "platform.sidebar.pricing",
        icon: CircleDollarSign,
        requiredPermission: showPricingPageClient ? undefined : "Pricing",
        href: "/pricing",
    },
    // {
    //     label: "עזרה",
    //     keyName: "platform.sidebar.help",
    //     icon: CircleHelp,
    //     href: "/help",
    // },
    {
        keyName: "platform.sidebar.admin",
        label: "אדמין",
        adminOnly: true,
        icon: AdjustmentsHorizontalIcon,
        children: [
            {
                keyName: "platform.sidebar.admin.users",
                label: "משתמשים",
                href: "/admin/users",
                requiredPermission: "Users",
            },
            {
                keyName: "platform.sidebar.admin.content",
                label: "תכנים",
                href: "/admin/content",
                requiredPermission: "Content",
            },
            {
                keyName: "platform.sidebar.admin.admins",
                label: "אדמינים",
                href: "/admin/manage",
                requiredPermission: "ManageAdmins",
            },
            {
                keyName: "platform.sidebar.admin.design",
                label: "עיצוב",
                href: "/admin/design",
                requiredPermission: "Design",
            },
        ],
    },
];

export default function Sidebar({ logo }: { logo: string }) {
    const [sheetOpen, setSheetOpen] = useState(false);

    const pathname = usePathname();

    useEffect(() => {
        setSheetOpen(false);
    }, [pathname]);

    return (
        <>
            <aside className="bg-white hidden lg:flex max-w-[240px] w-full border-e">
                <SidebarContent logo={logo} navigation={navigation} />
            </aside>

            <div className="lg:hidden border-b flex flex-row justify-between items-center h-16 px-6">
                <Sheet open={sheetOpen} onOpenChange={setSheetOpen}>
                    <SheetTrigger>
                        <div className="flex w-full justify-center items-center">
                            <AlignRight className="size-5" />
                        </div>
                    </SheetTrigger>
                    <SheetContent
                        className="w-[260px] bg-white py-0 px-0"
                        xClassName="text-white !left-[-28px]"
                    >
                        <SidebarContent logo={logo} navigation={navigation} />
                    </SheetContent>
                </Sheet>

                <div className="flex shrink-0 items-center">
                    <Image alt="Yeda" src={logo} height={22} width={107} />
                </div>
            </div>
        </>
    );
}
