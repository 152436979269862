"use client";

import { ChevronsUpDown, LogOut, Settings, Sparkles } from "lucide-react";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useAuth } from "@/features/auth/hooks/useAuth";
import { useIsMobile } from "@/hooks/use-mobile";
import { T } from "@tolgee/react";
import { signOut } from "next-auth/react";
import { User } from "next-auth";
import { usePostHog } from "posthog-js/react";

export default function UserButton() {
    const { user } = useAuth();
    const isMobile = useIsMobile();
    const posthog = usePostHog();

    const logOut = () => {
        posthog.reset();
        signOut();
    };

    if (!user) return <></>;

    return (
        <DropdownMenu dir="rtl">
            <DropdownMenuTrigger asChild>
                <div className="mt-2 cursor-pointer p-2 flex flex-row justify-between items-center w-full hover:bg-muted transition rounded-md">
                    <UserDetails user={user} />
                    <ChevronsUpDown className="size-4" />
                </div>
            </DropdownMenuTrigger>
            <DropdownMenuContent
                className="w-[--radix-dropdown-menu-trigger-width] min-w-56 rounded-lg"
                side={isMobile ? "top" : "left"}
                align={isMobile ? "center" : "end"}
                sideOffset={4}
            >
                <DropdownMenuLabel className="p-0 font-normal">
                    <UserDetails user={user} />
                </DropdownMenuLabel>
                <DropdownMenuSeparator />
                <a href="/pricing">
                    <DropdownMenuItem>
                        <Sparkles />
                        <T
                            keyName="user-button.dropdown.upgrade-plan"
                            defaultValue="שדרוג המנוי"
                        />
                    </DropdownMenuItem>
                </a>
                <a href="/settings">
                    <DropdownMenuItem>
                        <Settings />
                        <T
                            keyName="user-button.dropdown.settings"
                            defaultValue="הגדרות"
                        />
                    </DropdownMenuItem>
                </a>
                <DropdownMenuItem onClick={logOut}>
                    <LogOut />
                    <T
                        keyName="user-button.dropdown.logout"
                        defaultValue="התנתקות"
                    />
                </DropdownMenuItem>
            </DropdownMenuContent>
        </DropdownMenu>
    );
}

const UserDetails = ({ user }: { user: User }) => {
    return (
        <div className="flex flex-row gap-2">
            <Avatar className="size-8 rounded-lg">
                <AvatarImage src={user.image || ""} alt={user.name || "user"} />
                <AvatarFallback className="rounded-lg">
                    {user.email?.charAt(0).toUpperCase()}
                </AvatarFallback>
            </Avatar>
            <div className="grid flex-1 text-sm leading-tight">
                <span className="truncate font-semibold">{user.name}</span>
                <span className="truncate text-xs">{user.email}</span>
            </div>
        </div>
    );
};
