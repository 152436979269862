"use client";

import Image from "next/image";
import SidebarSingleItem from "./sidebarSingleItem";
import SidebarMultiItem from "./sidebarMultiItem";
import {
    NavigationItem,
    NavigationMultiItem,
    NavigationSingleItem,
} from "./sidebar";
import UserButton from "./userButton";
import { useAuth } from "@/features/auth/hooks/useAuth";

export default function SidebarContent({
    navigation,
    logo,
}: {
    navigation: NavigationItem[];
    logo: string;
}) {
    const { user } = useAuth();
    const hidePricing = user?.hidePricing;
    const filteredNavigation = navigation.filter(
        (item) => !((item as any)?.href === "/pricing" && hidePricing)
    );

    return (
        <div className="flex w-full h-full flex-col gap-y-5">
            <div className="px-6 pt-4 flex h-16 shrink-0 items-center">
                <Image alt="Xsiter" src={logo} height={22} width={107} />
            </div>
            <nav className="flex flex-1 flex-col">
                <ul role="list" className="px-6 flex flex-1 flex-col gap-y-7">
                    <li>
                        <ul role="list" className="-mx-2 space-y-1">
                            {filteredNavigation.map((item) => (
                                <li key={item.label}>
                                    {/* @ts-ignore */}
                                    {item?.children ? (
                                        <SidebarMultiItem
                                            item={item as NavigationMultiItem}
                                        />
                                    ) : (
                                        <SidebarSingleItem
                                            item={item as NavigationSingleItem}
                                        />
                                    )}
                                </li>
                            ))}
                        </ul>
                    </li>
                </ul>
                <div className="mt-auto flex w-full p-2">
                    <UserButton />
                </div>
            </nav>
        </div>
    );
}
