import { type ClassValue, clsx } from "clsx";
import { ForwardRefExoticComponent, RefAttributes, SVGProps } from "react";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs));
}

export type HeroIcon = ForwardRefExoticComponent<
    Omit<SVGProps<SVGSVGElement>, "ref"> & {
        title?: string;
        titleId?: string;
    } & RefAttributes<SVGSVGElement>
>;

export const formatIsoStringDate = (
    isoStringDate: string,
    includeTime?: boolean
) => {
    return new Date(isoStringDate).toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "2-digit",
        ...(includeTime && { hour: "2-digit", minute: "2-digit" }),
    });
};

export const formatSecondsToMinutes = (numOfSeconds: number) => {
    const hours = Math.floor(numOfSeconds / 3600);
    const minutes = Math.floor((numOfSeconds % 3600) / 60);
    const seconds = Math.floor(numOfSeconds % 60);

    if (hours > 0) {
        return `${hours.toString().padStart(2, "0")}:${minutes
            .toString()
            .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
    }

    return `${minutes.toString().padStart(2, "0")}:${seconds
        .toString()
        .padStart(2, "0")}`;
};

export function formatFileSize(size: number): string {
    if (size < 1024 * 1024) {
        return `${(size / 1024).toFixed(2)} KB`;
    } else if (size < 1024 * 1024 * 1024) {
        return `${(size / (1024 * 1024)).toFixed(2)} MB`;
    } else {
        return `${(size / (1024 * 1024 * 1024)).toFixed(2)} GB`;
    }
}

export function getFileType(mimeType: string): string {
    const typeParts = mimeType.split("/");
    return typeParts.length > 1 ? typeParts[1] : typeParts[0];
}

export const PRIMARY_COLOR = "#000F61";

export const isWhite = (color: string) =>
    color === "#fff" ||
    color === "#FFF" ||
    color === "#ffffff" ||
    color === "#FFFFFF";
export const isBlack = (color: string) =>
    color === "#000" || color === "#000000";

export const HOMEPAGE = "/home";

export const datadogEnabled = process.env.ENV !== "local";

export const getVideoEmbedUrl = (pullZone: string, videoId: string) => {
    if (process.env.NEXT_PUBLIC_ENV === "prod") {
        return `https://iframe.yedatech.io/embed/${pullZone}/${videoId}`;
    } else if (process.env.NEXT_PUBLIC_ENV === "dev") {
        return `https://dev.iframe.yedatech.io/embed/${pullZone}/${videoId}`;
    } else {
        return `http://localhost:3002/embed/${pullZone}/${videoId}`;
    }
};

export const getChatbotScript = (chatbotId: string) => {
    const scriptSrc = getChatbotScripSrc();
    const embedCode = `<script src="${scriptSrc}" data-env="${process.env.NEXT_PUBLIC_ENV}" data-chatbot-id="${chatbotId}" async></script>`;
    return embedCode;
};

export const getChatbotScripSrc = () => {
    if (process.env.NEXT_PUBLIC_ENV === "prod") {
        return `https://chat.yedalabs.io/embed.js`;
    } else if (process.env.NEXT_PUBLIC_ENV === "dev") {
        return `https://dev.chat.yedalabs.io/embed.js`;
    } else {
        return `http://localhost:3003/embed.js`;
    }
};

export const showPricingPageClient =
    process.env.NEXT_PUBLIC_ENV !== "prod" ||
    (typeof window !== "undefined" &&
        window.location.href.includes("yedalabs"));

export const supportNumber = "072-338-5091";
export const supportEmail = "info@yedalms.io";
export const address = "יגאל אלון 123, תל אביב";
export const tosLink = "https://yedalabs.io/terms";

export const formatTimeAgo = (date: Date): string => {
    const now = new Date();
    const diffInSeconds = Math.floor((now.getTime() - date.getTime()) / 1000);

    if (diffInSeconds < 60) {
        return "לפני פחות מדקה";
    }

    const diffInMinutes = Math.floor(diffInSeconds / 60);
    if (diffInMinutes < 60) {
        return diffInMinutes === 1 ? "לפני דקה" : `לפני ${diffInMinutes} דקות`;
    }

    const diffInHours = Math.floor(diffInMinutes / 60);
    if (diffInHours < 24) {
        return diffInHours === 1 ? "לפני שעה" : `לפני ${diffInHours} שעות`;
    }

    const diffInDays = Math.floor(diffInHours / 24);
    if (diffInDays < 7) {
        return diffInDays === 1 ? "לפני יום" : `לפני ${diffInDays} ימים`;
    }

    const diffInWeeks = Math.floor(diffInDays / 7);
    if (diffInWeeks < 4) {
        return diffInWeeks === 1 ? "לפני שבוע" : `לפני ${diffInWeeks} שבועות`;
    }

    const diffInMonths = Math.floor(diffInDays / 30);
    if (diffInMonths < 12) {
        return diffInMonths === 1 ? "לפני חודש" : `לפני ${diffInMonths} חודשים`;
    }

    const diffInYears = Math.floor(diffInDays / 365);
    return diffInYears === 1 ? "לפני שנה" : "לפני יותר משנה";
};

export const formatStorageSize = (
    bytes: number
): { value: number; unit: "MB" | "GB" | "TB" } => {
    // Convert bytes to decimal gigabytes (1 GB = 10^9 bytes)
    const gb = bytes / 1e9;

    if (gb < 1) {
        // Convert to decimal megabytes
        return { value: Math.round(bytes / 1e6), unit: "MB" };
    } else if (gb >= 1000) {
        // Convert to decimal terabytes
        return { value: Math.round(gb / 1000), unit: "TB" };
    } else {
        return { value: Math.round(gb), unit: "GB" };
    }
};
